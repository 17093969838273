// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <div style={{ marginBottom: 50 }}>
      <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>
      <p>
        This website does not collect any personal information of visitors.
      </p>
      <Link to="/" title="Back" className="block text-lg font-bold text-center my-9">
        &larr; Back
      </Link>
    </div>
  </Layout>
)

export default PrivacyPage
